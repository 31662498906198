<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        layout="horizontal"
        :label-col="{span:6}"
        :wrapper-col="{span:12}"
      >
        <br/>
          <a-form-model-item label=" " :colon="false">
            <span style="color:#1890ff;">大使/驿站-用户，存在宣传关系可得宣传奖励</span>
          </a-form-model-item>
          <a-form-model-item label="注册后天数" prop="regDay">
            <a-input-number v-model="form.regDay" style="width:500px;" />
            <div style="color:#1890ff;font-size: 13px;">邀请用户完成注册后{ {{this.form.regDay}} }天内，二者存在宣传关系</div>
          </a-form-model-item>
          <a-form-model-item label="顺延天数" prop="delayDay">
            <a-input-number v-model="form.delayDay" style="width:500px;" />
            <div style="color:#1890ff;font-size: 13px;">{ {{this.form.regDay}} }天内用户每完成一次购买，二者宣传关系可自{ {{this.form.regDay}} }天起自动顺延一次，{ {{this.form.delayDay}} }天。顺延截止日期内，如未完成购买，自顺延截止日期的次日起，二者宣传关系失效。</div>
          </a-form-model-item>
        <br/>
        <a-form-model-item label=" " :colon="false">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-model-item>
    </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    const validInteger = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) {
          return callback(new Error('请输入数值'))
        } else {
          const re = /^[0-9]*[1-9][0-9]*$/
          const rsCheck = re.test(value)
          if (!rsCheck) {
            return callback(new Error('请输入正整数'))
          }
        }
      }
      callback()
    }
    return {
      visible: false,
      breadList: [
        {
          name: '宣传关系设置',
          url: ''
        }
      ],
      form: {
        regDay: null,
        delayDay: null
      },
      rules: {
        regDay: [
          { required: true, message: '请输入注册后天数', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ],
        delayDay: [
          { required: true, message: '请输入顺延天数', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getSalePublicity()
  },
  computed: {
  },
  methods: {
    async getSalePublicity () {
      const res = await this.$store.dispatch('http', {
        api: 'getSalePublicity'
      })
      if (res) {
        this.form = {
          regDay: res.regDay,
          delayDay: res.delayDay
        }
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          await this.$store.dispatch('http', {
            api: 'saveSalePublicity',
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '保存成功~' })
          this.getSalePublicity()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 30px;
  }
}
</style>
